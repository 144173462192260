.keyboard-key {
    white-space:pre;
    height: 60px;
    border-radius: 5px;
    font-size: 1em;
    margin: 3px 3px;
    padding: 10px;
    font-size: 16px;
    font-family: monospace ;
    font-weight: bold;    
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    flex: 1;
}

.keyboard-key:empty {
    background-color: transparent !important;
    flex: 0;
}