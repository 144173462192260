.letter {
    border-radius: 5px;
    font-size: 1.5em;
    margin: 1px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1 ;
}

.letter.pop {
    animation: 200ms ease-out popIn;
}

@keyframes popIn {
    from {
        transform: scale(0.8);
    }

    40% {
        transform: scale(1.1);
    }
}

.flip {
    animation-timing-function: ease-in-out;
    animation-name: flipIn;
    animation-duration: 500ms;
}

@keyframes flipIn {
    0% {
        transform: rotateX(0);
        color: black;
    }

    50% {
        transform: rotateX(-90deg);
        color: black;
    }

    51% {
        color: white;
    }

    55% {
        transform: rotateX(-90deg);
        color: white;
    }

    0% {
        transform: rotateX(0);
        color: white;
    }
}