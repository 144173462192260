.word-line {
    display: grid;
    justify-content: center;
    width: 100%;
    grid-gap: 10px;
    margin: 10px 0;
    box-sizing: border-box;
}

.word-animation{
    animation: 300ms ease-out wiggle 1;
}

@keyframes wiggle{
    0%{
        transform:translateX(-10px);
    }
    20%{
        transform:translateX(10px);
    }
    40%{
        transform:translateX(-10px);
    }
    60%{
        transform:translateX(10px);
    }
    80%{
        transform:translateX(-10px);
    }
    100%{
        transform:translateX(0);
    }
}
